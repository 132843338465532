<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import NotificationService from '@/services/notification'
import moment from 'moment';
moment.locale('pt-BR');

export default {
  page: {
    title: "Notificações Agendadas",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Notificações Agendadas",
      notifications: [],
      notificationId: '',
      fields: [
        { key: 'title', sortable: true, label: 'Título' },
        { key: 'message', sortable: true, label: 'Mensagem', width: 200 },
        { key: 'scheduleDate', sortable: true, label: 'Data/Hora Envio', formatter: (value) => {
            return moment(value).format('DD/MM/YYYY HH:mm');
        } },
        { key: 'status', sortable: true, label: 'Status' },
        { key: 'actions', label: 'Ações' },
      ],
      modalError: '',
      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 20,
    };
  },
  computed: {
    rows() {
      return this.notifications.length;
    },
    startIndex() {
      return (this.currentPage * this.perPage) - this.perPage + 1;
    },
    endIndex() {
      const endIndex = (this.currentPage * this.perPage);
      if (this.notifications.length < endIndex) {
        return this.notifications.length;
      }

      return endIndex;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load(loader = null) {
      if (!loader) {
        loader = this.$loading.show();
      }

      NotificationService.getNotifications().then(res => {
        this.notifications = res.data;

        loader.hide();
      }, err => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
    },
    async remove() {
      this.$bvModal.hide('modal-remove');

      let loader = this.$loading.show();
      await NotificationService.removeNotification(this.notificationId);

      this.$toast.open('Registro removido com sucesso');

      this.load(loader);
    },
    removeNotification(item) {
      this.notificationId = item._id;
      this.$bvModal.show('modal-remove');
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"> 
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="notifications"
                :fields="fields"
                responsive="sm"
                head-variant="light"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class=" {
                      'badge-soft-success': row.item.send,
                      'badge-soft-warning': !row.item.send,
                    }"
                  >{{row.item.send ? 'Enviada' : 'Agendada'}}</span>
                </template>   

                <template v-slot:cell(message)="row">
                  <div style="width: 100">{{ row.item.message }}</div>
                </template>   

                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>

                    <b-dropdown-item v-b-modal.modal-remove @click="removeNotification(row.item)" :disabled="row.item.send">
                      <i class="fas fa-trash-alt text-danger mr-2 mt-2"></i>
                      Excluir
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="row justify-content-md-between align-items-md-center mt-4">
          <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{rows}}</div>

          <div class="col-xl-5">
            <div class="text-md-right float-xl-right mt-2 pagination-rounded">
              <b-pagination
                v-model="currentPage" 
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-remove"
      title="Excluir Registro?"
      title-class="font-18"
      centered
    >
      <p>Confirma a exclusão da notificação agendada?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="remove">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .form-control:disabled {
    background-color: #eee !important;
  }
</style>